import { useRecoilState } from "recoil";
import React, { ReactComponentElement, useState } from "react";
import SideBar from "../../components/SideBar";
import menuAtom from "../../atoms/menu/menu.atom";
import { ModalContainer } from "../../components/ModalContainer";
import { useLocation } from "react-router";
import { Button, message, notification } from "antd";
import { LuMenu } from "react-icons/lu";
import { routes } from "../../router";
import { isEqual } from "../../context/utils";
import { useGetProfile, useGetSubLink } from "../../hooks/profile/profile";
import Gamification from "../../components/gamification";
import { MdClose } from "react-icons/md";
import { promptAtom } from "../../atoms/others/others.atom";

type Props = {
  children: ReactComponentElement<any>;
};
const MainLayout = ({ children }: Props) => {
  const [isPrompt, setIsPrompt] = useRecoilState(promptAtom);
  const [isOpen, setIsOpen] = useRecoilState(menuAtom);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const onPromptClose = () => setIsPrompt(false);
  const onMenuClose = () => setIsMenuOpen(false);
  const onMenuOpen = () => setIsMenuOpen(true);
  const onClose = () => setIsOpen(false);
  const onOpen = () => setIsOpen(true);
  const { pathname } = useLocation();

  const { isLoading: getSubLinkLoad, mutateAsync: getSubLinkAction } = useGetSubLink();
  const route = routes?.find((d) => isEqual(d?.path, pathname));
  const { data: getProfileData } = useGetProfile((res: any) => {
    const isCredit = Number(res?.user?.current_credit)
    const isPremium = isEqual(res?.user?.current_tier, "premium")
    if (!isCredit && !isPremium) { getSubLinkAction().then((res: any) => {
      notification.info({ message: "Welcome!", description: "Please note that you'll be rerouted to our subscription wall", key: "sub_info" });
      setTimeout(() => { window.location.href = res?.data?.url }, 5000);
    }) }
  });

  const handleBuyCredit = () => getSubLinkAction().then((res: any) => {
    message.info("Please note that you'll be rerouted to our subscription wall");
    setTimeout(() => { window.location.href = res?.data?.url }, 5000);
  })

  const isPremium = isEqual(getProfileData?.user?.current_tier, "premium")
  return (
    <ModalContainer>
      {(isPrompt && !isPremium) && (
        <div className="w-full top-prompt bg-primary text-white flex flex-col md:flex-row justify-center text-center items-center gap-3 p-2 px-5 relative">
          <p className="text-base font-bold">Running Low On Credits?</p>
          <Button onClick={handleBuyCredit} loading={getSubLinkLoad} className="hover:underline !text-white" type="text">Get Credits</Button>
          {/* <Link to="/settings?subscription=true" className="hover:underline">Get Credits</Link> */}
          <Button
            className="bg-[#101828] absolute top-2 right-5"
            onClick={onPromptClose}
            icon={<MdClose />}
            shape="circle"
            type="primary"
            size="small"
          />
        </div>
      )}
      <div className="w-full h-screen max-h-screen flex flex-col md:flex-row justify-between items-center md:justify-start p-0 m-0 md:bg-light font-montserrat overflow-y-hidden !relative">
        <div className="w-full bg-white flex gap-2 items-center md:relative md:hidden pt-5 px-5">
          <Button
            icon={<LuMenu className="!text-2xl !font-medium !bg-transparent" />}
            className="text-[#101828] !p-0 !m-0"
            onClick={onMenuOpen}
            type="text"
          />
          <p className="text-xl font-bold">{route?.label}</p>
        </div>
        {/* sidebar component can come in here  */}
        <div
          className={`w-0 h-0 ${
            isOpen ? "md:!w-[5%]" : "md:!w-[20%]"
          } md:h-full ${pathname === "/info" && "hidden"}`}
        >
          <SideBar
            isOpen={isOpen}
            onOpen={onOpen}
            onClose={onClose}
            isMenuOpen={isMenuOpen}
            onMenuClose={onMenuClose}
          />
        </div>

        <div
          className={`w-full h-full ${
            isOpen ? "md:!w-[95%]" : "md:!w-[80%]"
          } overflow-y-hidden`}
        >
          {/* main layout pages children  */}
          <div
            className={`w-full h-full bg-white ${
              pathname !== "/info" && " overflow-y-auto p-5 md:p-0"
            }`}
          >
            {children}
          </div>
        </div>

        <Gamification />
      </div>
    </ModalContainer>
  );
};

export default MainLayout;
