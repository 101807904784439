import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import {
  useFetchLectureFlashcard,
  usePostFlashcards,
} from "../../../../hooks/flashcards/flashcards";
import {
  handleObj,
  handleURL,
  isEqual,
  checkOddValue,
} from "../../../../context/utils";
import { useFetchLectureQuiz, usePostQuiz } from "../../../../hooks/quiz/quiz";
import {
  Button,
  Dropdown,
  Image,
  Modal,
  Spin,
  Tabs,
  Radio,
  Form,
  Input,
  Select,
} from "antd";
import {
  RiArrowGoBackFill,
  RiArrowGoBackLine,
  RiWhatsappFill,
} from "react-icons/ri";
import FlashCardIcon from "../../../../assets/icons/flashcardicon";
import QuizIcon from "../../../../assets/icons/quizicon";
import lectureAtom from "../../../../atoms/lecture/lecture.atom";
import successImg from "../../../../assets/images/success-image.png";
import { useRecoilValue } from "recoil";
import FlashcardSection from "./flashcard";
import QuizQuestionsSection from "./quizQuestions";
import DiscussSection from "./discuss";
import { ImSpinner } from "react-icons/im";
import { useGetLectureById } from "../../../../hooks/lecture/lecture";
import {
  useFetchSingleDiscuss,
  usePostLectureDiscuss,
} from "../../../../hooks/discuss/discuss";
import DiscussIcon from "../../../../assets/icons/discussicon";
import RecapIcon from "../../../../assets/icons/recapicon";
import {
  useFetchSingleRecap,
  usePostLectureRecap,
} from "../../../../hooks/recap/recap";
import RecapSection from "./recap";
import {
  FacebookMessengerShareButton,
  FacebookShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import { FaFacebook, FaFacebookMessenger, FaTelegram } from "react-icons/fa";
import ShareToUsersModal from "../../../../components/modals/ShareToUsersModal";
import { FaSquareXTwitter } from "react-icons/fa6";
import { GoShareAndroid } from "react-icons/go";
import { languages } from "../../../../constants";

const LectureDetailsSection = () => {
  const navigate = useNavigate();
  const { lecture } = useRecoilValue(lectureAtom);
  const [params, setParams] = useSearchParams();
  const contentId = params.get("content");
  const courseId = params.get("course");
  const materialId = params.get("id");
  const id = contentId || materialId;
  const [isSuccessOpen, setIsSuccessOpen] = useState(false);
  const activeAction = params.get("action");
  const [isCreate, setIsCreate] = useState(false);

  const isCourse = contentId && courseId;

  const searchParams = Object.fromEntries(
    useLocation()
      ?.search?.replaceAll("?", "")
      ?.split("&")
      ?.map((d) => d?.split("="))
      ?.filter(([key, val]) => val && !checkOddValue(val))
  );
  const [openShare, setOpenShare] = useState(false);

  const onSuccessClose = () => setIsSuccessOpen(false);
  const onSuccessOpen = () => setIsSuccessOpen(true);
  const onCreClose = () => setIsCreate(false);
  const onCreOpen = () => {
    setIsCreate(true);
    setOpenShare(false);
    setIsSuccessOpen(false);
  };

  const goBack = () =>
    isCourse ? setParams({ id: materialId } as any) : navigate("/");

  const actionKeys = {
    discussions: "discussions",
    flashcard: "flashcards",
    recap: "recaps",
    quiz: "quiz",
  };

  const [selectedOption, setSelectedOption] = useState(false);

  const handleOptionChange = (e: any) => {
    setSelectedOption(e.target.value);
  };

  const {
    data: getFlashcardData,
    isFetching: getFlashcardLoad,
    refetch: getFlashcardRefetch,
  } = useFetchLectureFlashcard(id!, isEqual(activeAction, "flashcard"));

  const {
    data: getDiscussData,
    isFetching: getDiscussLoad,
    refetch: getDiscussRefetch,
  } = useFetchSingleDiscuss(id!, isEqual(activeAction, "discussions"));

  const {
    data: getQuizData,
    isFetching: getQuizLoad,
    refetch: getQuizRefetch,
  } = useFetchLectureQuiz(id!, isEqual(activeAction, "quiz"));

  const {
    data: getRecapData,
    isFetching: getRecapLoad,
    refetch: getRecapRefetch,
  } = useFetchSingleRecap(id!, isEqual(activeAction, "recap"));

  const { refetch: getLectureFetch } = useGetLectureById(lecture?._id!);

  const isNotAllowed = useMemo(
    () => ["discussions"]?.includes(activeAction!),
    [activeAction]
  );
  const shareKey = useMemo(() => {
    const actionTypes = {
      flashcard: "flashcards",
      recap: "recaps",
      quiz: "quiz",
    };
    return lecture?.[actionTypes?.[activeAction as keyof typeof actionTypes]];
  }, [activeAction, lecture]);

  const successAction = (res: any) => {
    onSuccessOpen();
    getQuizRefetch();
    getLectureFetch();
    getRecapRefetch();
    getDiscussRefetch();
    getFlashcardRefetch();
    setParams(
      handleObj({
        content: res?.data?._id,
        action: activeAction,
        id: res?.data?._id,
        ...searchParams,
      }) as any
    );
    onCreClose();
  };

  const {
    // data: postQuizData,
    mutate: postQuizAction,
    isLoading: postQuizLoad,
  } = usePostQuiz(successAction);

  const {
    // data: postFlashcardData,
    mutate: postFlashcardAction,
    isLoading: postFlashcardLoad,
  } = usePostFlashcards(successAction);

  const { mutate: postDiscussAction, isLoading: postDiscussLoad } =
    usePostLectureDiscuss(successAction);

  const { mutate: postRecapAction, isLoading: postRecapLoad } =
    usePostLectureRecap(successAction);

  // useEffect(() => {
  //   if (id !== "undefined") {
  //     refectchLecturesById();
  //     if (activeAction === "quiz") {
  //       console.log("refetching quiz");
  //       getQuizRefetch();
  //     } else if (activeAction === "flashcard") {
  //       console.log("refetching flashcard");
  //       getFlashcardRefetch();
  //     } else if (activeAction === "recap") {
  //       console.log("refetching recap");
  //       getRecapRefetch();
  //     } else if (activeAction === "discussions") {
  //       console.log("refetching discuss");
  //       getDiscussRefetch();
  //     }
  //   }
  // }, [id]);

  // if (postFlashcardData?.data?._id !== undefined) {
  //   console.log("flashcardDataId", postFlashcardData?.data?._id);
  //   setParams({ action: activeAction, id: postQuizData?.data?._id } as {
  //     action: string;
  //     id: string;
  //   });
  //   myPromise.then((successMessage) => {
  //     // if (id !== undefined) {
  //     getFlashcardRefetch();
  //     // }
  //   });
  // }

  const handleCreateQuiz = (value: any) => {
    const payload = {
      lecture_id: lecture?._id,
      file_url: lecture?.contentUrl,
      file_type: lecture?.contentType,
      file_name: lecture?.lecture_title,
      allow_result_public: selectedOption,
      language: value?.language || "English",
      title: `${value?.quiz_title || lecture?.lecture_title}`,
    };
    postQuizAction(payload);
  };

  const handleCreateFlashcard = (value: any) => {
    const payload = {
      lecture_id: lecture?._id,
      file_url: lecture?.contentUrl,
      file_type: lecture?.contentType,
      file_name: lecture?.lecture_title,
      allow_result_public: selectedOption,
      language: value?.language || "English",
      title: `${value?.flashcard_title || lecture?.lecture_title}`,
    };
    postFlashcardAction(payload);
  };

  const handleCreateRecap = (value: any) => {
    const payload = {
      lecture_id: lecture?._id,
      file_url: lecture?.contentUrl,
      file_type: lecture?.contentType,
      file_name: lecture?.lecture_title,
      allow_result_public: selectedOption,
      language: value?.language || "English",
      title: `${value?.name || lecture?.lecture_title}`,
    };
    postRecapAction(payload);
  };

  const handleCreateDiscuss = (value: any) => {
    const payload = {
      lecture_id: lecture?._id,
      file_url: lecture?.contentUrl,
      file_type: lecture?.contentType,
      file_name: lecture?.lecture_title,
      language: value?.language || "English",
      title: `${value?.name || lecture?.lecture_title}`,
    };
    postDiscussAction(payload);
  };

  const tabs = useMemo(
    () => [
      {
        key: "quiz",
        active: lecture?.quiz,
        // data: getQuizData?.data,
        label: (
          <div className="flex items-center gap-3">
            <p>Quiz</p>
          </div>
        ),
      },
      {
        key: "flashcard",
        active: lecture?.flashcards,
        // data: getFlashcardData?.data,
        label: (
          <div className="flex items-center gap-3">
            <p>Flashcards</p>
          </div>
        ),
      },

      {
        key: "recap",
        active: lecture?.recaps,
        label: (
          <div className="flex items-center gap-3">
            <p>Recap</p>
          </div>
        ),
      },
      {
        key: "discussions",
        active: lecture?.discussions,
        label: (
          <div className="flex items-center gap-3">
            <p>Discuss</p>
          </div>
        ),
      },
    ],
    [lecture]
  );

  const handleTab = (action: string) => {
    if (isEqual(action, activeAction)) return;
    setParams(
      handleObj({
        ...searchParams,
        id: isCourse
          ? materialId
          : String(
              lecture?.[
                actionKeys?.[
                  action as keyof typeof actionKeys
                ] as keyof typeof lecture
              ]
            ),
        content: String(
          lecture?.[
            actionKeys?.[
              action as keyof typeof actionKeys
            ] as keyof typeof lecture
          ]
        ),
        action,
      }) as any
    );

    // if (action === "flashcard") {
    //   setParams({ action, id: lecture?.flashcards } as {
    //     action: string;
    //     id: string;
    //   });
    // }

    // if (action === "quiz") {
    //   setParams({ action, id: lecture?.quiz } as {
    //     action: string;
    //     id: string;
    //   });
    // }

    // if (action === "recap") {
    //   setParams({ action, id: lecture?.recaps } as {
    //     action: string;
    //     id: string;
    //   });
    // }

    // if (action === "discussions") {
    //   setParams({ action, id: lecture?.discussions } as {
    //     action: string;
    //     id: string;
    //   });
    // }
  };

  const isActionLoad =
    postQuizLoad || postFlashcardLoad || postRecapLoad || postDiscussLoad;

  const CreateContent = useMemo(
    () =>
      [
        {
          key: "quiz",
          component: (
            <Form
              onFinish={handleCreateQuiz}
              layout="vertical"
              className="bg-white"
            >
              {/* <Form.Item label="Name of Quiz" name="quiz_title">
                <Input
                  className="!rounded-xl"
                  placeholder="Enter quiz name"
                  size="large"
                />
              </Form.Item> */}
              <Form.Item hidden label="Select no of Questions">
                <div className="grid grid-cols-3 gap-3">
                  {Array.from(Array(3).keys()).map((idx) => (
                    <Button className="!rounded-xl" size="large">{`${
                      (idx + 1) * 10
                    } Questions`}</Button>
                  ))}
                </div>
              </Form.Item>
              <Form.Item hidden label="Quiz Duration">
                <div className="grid grid-cols-3 gap-3">
                  {Array.from(Array(3).keys()).map((idx) => (
                    <Button className="!rounded-xl" size="large">{`${
                      (idx + 1) * 10
                    } mins`}</Button>
                  ))}
                </div>
              </Form.Item>
              <Form.Item label="Language" name="language">
                <Select
                  size="large"
                  className="!rounded-xl"
                  placeholder="Select preferred language"
                >
                  {languages.map(({ name }) => (
                    <Select.Option value={name}>{name}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <div className="w-full flex flex-col items-start gap-2 mb-3">
                <span className="font-medium text-[16px] leading-[24px]">
                  Sharing access:
                </span>

                <Radio.Group
                  onChange={handleOptionChange}
                  value={selectedOption}
                >
                  <Radio value={false}>Only Me</Radio>
                  <Radio value={true}>Everyone</Radio>
                </Radio.Group>
              </div>
              <Button
                className="bg-primary !w-full"
                loading={isActionLoad}
                htmlType="submit"
                type="primary"
                size="large"
                shape="round"
              >
                Save Quiz
              </Button>
            </Form>
          ),
        },
        {
          key: "flashcard",
          component: (
            <Form layout="vertical" onFinish={handleCreateFlashcard}>
              {/* <Form.Item label="Name of Flashcards" name="flashcard_title">
                <Input
                  className="!rounded-xl"
                  placeholder="Enter flashcards name"
                  size="large"
                />
              </Form.Item> */}
              <Form.Item label="Language" name="language">
                <Select
                  size="large"
                  className="!rounded-xl"
                  placeholder="Select preferred language"
                >
                  {languages.map(({ name }) => (
                    <Select.Option value={name}>{name}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <div className="w-full flex flex-col items-start gap-2 mb-3">
                <span className="font-medium text-[16px] leading-[24px]">
                  Sharing access:
                </span>

                <Radio.Group
                  onChange={handleOptionChange}
                  value={selectedOption}
                >
                  <Radio value={false}>Only Me</Radio>
                  <Radio value={true}>Everyone</Radio>
                </Radio.Group>
              </div>

              <Button
                className="bg-primary !w-full"
                loading={isActionLoad}
                htmlType="submit"
                type="primary"
                size="large"
                shape="round"
              >
                Save Flashcards
              </Button>
            </Form>
          ),
        },
        {
          key: "recap",
          component: (
            <Form onFinish={handleCreateRecap} layout="vertical">
              {/* <Form.Item label="Name of Recaps" name="name">
                <Input
                  placeholder="Enter recaps name"
                  className="!rounded-xl"
                  size="large"
                />
              </Form.Item> */}
              <Form.Item label="Language" name="language">
                <Select
                  size="large"
                  className="!rounded-xl"
                  placeholder="Select preferred language"
                >
                  {languages.filter(({ name }) => isEqual(name, "English")).map(({ name }) => (
                    <Select.Option value={name}>{name}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <div className="w-full flex flex-col items-start gap-2 mb-3">
                <span className="font-medium text-[16px] leading-[24px]">
                  Sharing access:
                </span>

                <Radio.Group
                  onChange={handleOptionChange}
                  value={selectedOption}
                >
                  <Radio value={false}>Only Me</Radio>
                  <Radio value={true}>Everyone</Radio>
                </Radio.Group>
              </div>
              <Button
                className="bg-primary !w-full"
                loading={isActionLoad}
                htmlType="submit"
                type="primary"
                size="large"
                shape="round"
              >
                Save Recaps
              </Button>
            </Form>
          ),
        },
        {
          key: "discussions",
          component: (
            <Form onFinish={handleCreateDiscuss} layout="vertical">
              {/* <Form.Item label="Name of Discussion" name="name">
                <Input
                  placeholder="Enter discussion name"
                  className="!rounded-xl"
                  size="large"
                />
              </Form.Item> */}
              <Form.Item label="Language" name="language">
                <Select
                  size="large"
                  className="!rounded-xl"
                  placeholder="Select preferred language"
                >
                  {languages.map(({ name }) => (
                    <Select.Option value={name}>{name}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Button
                className="bg-primary !w-full"
                loading={isActionLoad}
                htmlType="submit"
                type="primary"
                size="large"
                shape="round"
              >
                Save Discussion
              </Button>
            </Form>
          ),
        },
      ]?.find((d) => isEqual(d.key, activeAction))?.component,
    [
      materialId,
      activeAction,
      isActionLoad,
      getQuizData,
      getRecapData,
      getFlashcardData,
      selectedOption,
    ]
  );

  const isLoading =
    getFlashcardLoad || getQuizLoad || getRecapLoad || getDiscussLoad;
  return (
    <Spin spinning={isLoading}>
      <div className="!w-full h-full py-[32px]">
        <div className="w-full flex flex-col items-start gap-3 sm:px-5 md:px-10">
          <div
            className="w-full flex flex-row items-center gap-2 cursor-pointer"
            onClick={goBack}
          >
            <RiArrowGoBackLine size={30} color="#1b1b1b" />
            <span className="text-[28px] leading-[38px] font-bold text-[#1b1b1b]">
              {lecture?.lecture_title}
            </span>
          </div>
          <div className="w-full flex flex-col md:flex-row justify-between items-center gap-5">
            <Tabs
              activeKey={activeAction! as string}
              defaultActiveKey={activeAction! as string}
              items={tabs}
              onChange={handleTab}
              className="w-full md:w-auto !p-0 !m-0"
            />
            <Dropdown
              trigger={["click"]}
              menu={{
                items: [
                  {
                    key: "facebook",
                    label: (
                      <FacebookShareButton
                        url={handleURL(activeAction as any, shareKey)}
                      >
                        <FaFacebook className="text-2xl text-[#0965fe]" />
                      </FacebookShareButton>
                    ),
                  },
                  {
                    key: "messenger",
                    label: (
                      <FacebookMessengerShareButton
                        url={handleURL(activeAction as any, shareKey)}
                        appId={shareKey}
                      >
                        <FaFacebookMessenger className="text-2xl text-[#0a7cff]" />
                      </FacebookMessengerShareButton>
                    ),
                  },
                  {
                    key: "whatsapp",
                    label: (
                      <WhatsappShareButton
                        url={handleURL(activeAction as any, shareKey)}
                      >
                        <RiWhatsappFill className="text-2xl text-[#25d366]" />
                      </WhatsappShareButton>
                    ),
                  },
                  {
                    key: "twitter",
                    label: (
                      <TwitterShareButton
                        url={handleURL(activeAction as any, shareKey)}
                      >
                        <FaSquareXTwitter className="text-2xl text-[#000]" />
                      </TwitterShareButton>
                    ),
                  },
                  {
                    key: "telegram",
                    label: (
                      <TelegramShareButton
                        url={handleURL(activeAction as any, shareKey)}
                      >
                        <FaTelegram className="text-2xl text-[#25a3e3]" />
                      </TelegramShareButton>
                    ),
                  },
                ],
              }}
            >
              <Button
                hidden={isNotAllowed}
                icon={<GoShareAndroid className="text-2xl" />}
                type="text"
              />
            </Dropdown>
          </div>
        </div>
        <div className="w--full h-full justify-center items-center">
          {activeAction === "flashcard" ? (
            <div>
              {getFlashcardData?.data?.flashCards?.length > 0 ? (
                <FlashcardSection />
              ) : (
                <div
                  // hidden={Boolean(getFlashcardData?.data?.length)}
                  className="w-full h-full"
                >
                  <div className="w-full h-full flex flex-col justify-center items-center gap-3 text-center">
                    <FlashCardIcon bg="#4970FC" color="#fff" />
                    <p className="text-[26px] font-extrabold text-secondary">
                      Flashcards
                    </p>
                    <p className="text-xl font-normal text-[#646462]">
                      Illuminate your ideas
                    </p>
                    <Button
                      onClick={() => {
                        onCreOpen();
                      }}
                      loading={postFlashcardLoad}
                      className="bg-primary !px-20 !rounded-2xl !h-[50px]"
                      type="primary"
                      size="large"
                    >
                      Create Flashcards
                    </Button>
                  </div>
                </div>
              )}
            </div>
          ) : activeAction === "quiz" ? (
            <div>
              {getQuizData?.data?.questions?.length! > 0 ||
              getQuizData?.data?.oeq.length! > 0 ? (
                <QuizQuestionsSection />
              ) : (
                <div
                  // hidden={Boolean(getQuizData?.lectures?.length)}
                  className="w-full h-full"
                >
                  <div className="w-full h-full flex flex-col justify-center items-center gap-3 text-center">
                    <QuizIcon bg="#4970FC" color="#fff" />
                    <p className="text-[26px] font-extrabold text-secondary">
                      Quiz
                    </p>
                    <p className="text-xl font-normal text-[#646462]">
                      Test your Knowledge
                    </p>
                    <Button
                      onClick={() => {
                        onCreOpen();
                      }}
                      loading={postQuizLoad}
                      className="bg-primary !px-20 !rounded-2xl !h-[50px]"
                      type="primary"
                      size="large"
                    >
                      Create Quiz
                    </Button>
                  </div>
                </div>
              )}
            </div>
          ) : activeAction === "recap" ? (
            <div className="w-full h-full">
              {!getRecapData?.data ? (
                <div className="w-full h-full flex flex-col justify-center items-center gap-3 text-center">
                  <RecapIcon bg="#4970FC" color="#fff" />
                  <p className="text-[26px] font-extrabold text-secondary">
                    Recaps
                  </p>
                  <p className="text-xl font-normal text-[#646462]">
                    Test your Knowledge
                  </p>
                  <Button
                    onClick={() => {
                      onCreOpen();
                    }}
                    loading={postRecapLoad}
                    className="bg-primary !px-20 !rounded-2xl !h-[50px]"
                    type="primary"
                    size="large"
                  >
                    Create Recaps
                  </Button>
                </div>
              ) : (
                <div>
                  {getRecapData?.data?.status === "Success" ? (
                    <RecapSection />
                  ) : (
                    <div className="w-full h-full text-center p-5 md:p-10 bg-white rounded-2xl flex flex-col justify-center gap-5 items-center">
                      <ImSpinner className="text-[100px] animate-spin text-primary" />
                      <p className="text-[30px] font-extrabold text-dark">
                        Processing your <br /> Recap
                      </p>
                      <p className="text-sm font-medium text-[#646462]">
                        Please hold on while we process your Recap
                      </p>
                    </div>
                  )}
                </div>
              )}
            </div>
          ) : activeAction === "discussions" ? (
            <div>
              {getDiscussData?.data?.chats?.length > 0 ? (
                <DiscussSection />
              ) : (
                <div className="w-full h-full">
                  <div className="w-full h-full flex flex-col justify-center items-center gap-3 text-center">
                    <DiscussIcon bg="#4970FC" color="#fff" />
                    <p className="text-[26px] font-extrabold text-secondary">
                      Discuss
                    </p>
                    <p className="text-xl font-normal text-[#646462]">
                      Elevate your Dialogue
                    </p>
                    <Button
                      onClick={() => {
                        onCreOpen();
                      }}
                      loading={postDiscussLoad}
                      className="bg-primary !px-20 !rounded-2xl !h-[50px]"
                      type="primary"
                      size="large"
                    >
                      Create Discussion
                    </Button>
                  </div>
                </div>
              )}
            </div>
          ) : (
            "hello"
          )}
        </div>

        {/* success modal >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */}
        <Modal
          styles={{
            content: {
              background: "none",
              padding: 0,
            },
          }}
          onCancel={onSuccessClose}
          open={isSuccessOpen}
          closeIcon={false}
          footer={false}
        >
          <div
            className="w-full h-full cursor-pointer"
            onClick={() => {
              onSuccessClose();
              // setGetLectData({
              //   lectures: [{}, {}, {}],
              // });
            }}
          >
            <Image
              alt="success"
              src={successImg}
              preview={false}
              className="!w-full"
            />
          </div>
        </Modal>
        <ShareToUsersModal
          open={openShare}
          setOpenShare={setOpenShare}
          type="lectures"
        />

        {/* create from content modal >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */}
        <Modal
          onCancel={onCreClose}
          closeIcon={false}
          open={isCreate}
          footer={false}
          styles={{
            content: {
              background: "none",
              padding: 0,
            },
          }}
        >
          <div className="w-full h-full relative">
            <Button
              icon={<RiArrowGoBackFill className="text-xl text-white" />}
              className="!bg-transparent absolute top-7 left-10"
              onClick={() => {
                onCreClose();
              }}
              type="text"
            />
            <div className="text-center w-full h-full flex flex-col justify-center gap-5 items-center rounded-t-2xl bg-primary text-white p-5 md:px-10 relative">
              <p className="text-[32px] font-semibold capitalize">{` Create ${activeAction}`}</p>
              <p className="text-sm font-normal capitalize">{`click the button below to create a personalized ${activeAction}.`}</p>
            </div>
            <div className="bg-white rounded-b-2xl p-5 md:px-10">
              {CreateContent}
            </div>
          </div>
        </Modal>
      </div>
    </Spin>
  );
};

export default LectureDetailsSection;
