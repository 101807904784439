import {
  useGetFlashcard,
  useMemorizeFlashcards,
  usePostFlashcardImage,
} from "../../../../hooks/flashcards/flashcards";
import {
  Button,
  Divider,
  Image,
  Modal,
  Radio,
  Spin,
} from "antd";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { MdCancel } from "react-icons/md";
import { useNavigate, useSearchParams } from "react-router-dom";
import { isEqual } from "../../../../context/utils";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import searchCiteIcon from '../../../../assets/icons/halfpapericon.png';
import { FeedbackModal } from "../../../../components/modals/FeedbackModal";
import { useDropDownContent } from "../../../../hooks/otherhooks";
import DropDownFeedComponent from "../../../../components/DropDownFeedComponent";
import CustomizeModal from "../../../../components/modals/CustomizeFlashcardModal";
import { RateLecturerModal } from "../../../../components/modals/RateLecturerModal";
import ShareToUsersModal from "../../../../components/modals/ShareToUsersModal";
import MatchingFlashcards from "../../../../components/modals/MatchingFlashcards";
import AddFlashcardModal from "../../../../components/modals/AddFlashcardModal";

const FlashcardSection = () => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const contentId = params.get("content");
  const materialId = params.get("id");
  const id = contentId || materialId;
  const onOpen = () => setIsOpen(true)
  const onClose = () => setIsOpen(false)
  const [isOpen, setIsOpen] = useState(false);
  const [activeQuest, setActiveQuest] = useState(0);
  const [isView, setIsView] = useState(false);
  const [showCustomize, setShowCustomize] = useState(false);
  const pdfRef = useRef<HTMLDivElement>(null);
  const [canAdd, setCanAdd] = useState(false);
  const [customized, setCustomized] = useState(false);
  const [showFeedBack, setShowFeedBack] = useState(false);
  const [openShare, setOpenShare] = useState(false);
  const [showRateLecturer, setShowRateLecturer] = useState(false);
  const [showDropDown, setShowDropDown] = useState(false);
  const [canPractice, setCanPractice] = useState(false);
  const onViewRand = () => setIsView(!isView);
  const { dropDownOptions } = useDropDownContent({
    setShowFeedBack,
    setShowDropDown,
    exportToPDF: true,
    exportToPDFFunc: () => toPdf(),
    canCustomize: true,
    customizeFunc: customizeFunc,
    canRateLecturer: true,
    setShowRateLecturer,
    shareToUser: () => shareToUser(),
    type: "flashcards",
  });

  function customizeFunc() {
    setShowCustomize(true);
  }

  const shareToUser = () => {
    setOpenShare(true);
  };

  const goBack = () => navigate("/");

  const {
    data: getFlashcardData,
    isFetching: getFlashcardLoad,
    refetch: refetchGetFlashcardData,
  } = useGetFlashcard(id!);

  const {
    mutate: postMemorizeAction,
    isLoading: postMemorizeLoad,
  } = useMemorizeFlashcards(id!, refetchGetFlashcardData);

  const {
    data: postFlashImgData,
    mutate: postFlashImgAction,
    isLoading: postFlashImgLoad,
  } = usePostFlashcardImage(id!, onOpen)

  const CurrentFlashcard: any = useMemo(
    () => getFlashcardData?.data?.flashCards?.[activeQuest],
    [activeQuest, getFlashcardData?.data?.flashCards]
  );

  const toPdf = () => {
    const input = pdfRef.current;
    if (input) {
      (html2canvas as any)(input, { scale: 2 }).then((canvas: any) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF({
          orientation: "portrait",
          unit: "px",
          format: [canvas.width, canvas.height],
        });

        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

        pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);

        let contentHeight = pdfHeight;
        while (contentHeight > pdf.internal.pageSize.getHeight()) {
          pdf.addPage();
          contentHeight -= pdf.internal.pageSize.getHeight();
        }

        pdf.save(getFlashcardData?.data?.name + ".pdf");
      });
    }
  };

  const handleMemorization = (word: string, meaning: string, value: string) => {
    const payload = {
      flash_cards: [{word, meaning}],
      memorized: value,
    };
    postMemorizeAction(payload);
  };

  useEffect(() => {
    if (customized) {
      refetchGetFlashcardData();
      setCustomized(false);
    }
  }, [customized]);

  const handleGenFlashImg = () => postFlashImgAction({text_prompt: `A picture of ${CurrentFlashcard?.word}`})
  return (
    <Spin spinning={getFlashcardLoad}>
      <div className="w-full min-h-full flex flex-col justify-between items-center md:py-5 space-y-5">
        <div className="w-full flex items-center justify-between gap-2 md:px-5">
          <div className="w-[90%] flex items-center md:px-10 gap-5">
            <MdCancel className="cursor-pointer text-3xl" onClick={goBack} />
            <div className="w-full pb-1 flex flex-nowrap items-center gap-3 overflow-x-auto">
              {getFlashcardData?.data?.flashCards?.map(
                (d: any, idx: number) => (
                  <Button
                    type="primary"
                    onClick={() => setActiveQuest(idx)}
                    className={`!w-[150px] !h-[13px] ${
                      isEqual(idx, activeQuest) ? "bg-primary" : "bg-fint"
                    }`}
                  />
                )
              )}
            </div>
          </div>

          <div className="w-[24px]">
            <DropDownFeedComponent
              dropDownOptions={dropDownOptions}
              setShowDropDown={setShowDropDown}
              showDropDown={showDropDown}
            />
          </div>
        </div>

        <div className="w-full md:w-[400px] flex items-center justify-center gap-5 mx-auto border border-[#f7f6f8] rounded-lg shadow-xl drop-shadow-xl px-5 py-10 overflow-y-auto">
          <div className="flex items-center justify-center gap-5 flex-col w-full text-center">
            <p
              hidden={isView}
              className="text-dark font-bold text-[28px] leading-[40px]"
            >{CurrentFlashcard?.word}</p>
            <p
              hidden={!isView}
              className="text-dark font-bold text-[28px] leading-[40px]"
            >{CurrentFlashcard?.meaning}</p>
            <div hidden={!isView} className="w-full space-y-5">
              <p className="text-sm font-normal text-[#676767]">How familiar are you with this word?</p>
              <Radio.Group
                onChange={({target:{value}}) => handleMemorization(
                  CurrentFlashcard?.word,
                  CurrentFlashcard?.meaning,
                  value
                )}
                value={CurrentFlashcard?.memorized}
                style={{width: "100%"}}
                buttonStyle="solid"
                className="!w-full"
                size="large"
              >
                {["Repeat", "Excellent", "Simple"]?.map(d => (
                  <Radio.Button value={d?.toLowerCase()} key={d}>{d}</Radio.Button>
                ))}
              </Radio.Group>
            </div>
            <Button
              block
              type="text"
              hidden={!isView}
              loading={postFlashImgLoad}
              onClick={handleGenFlashImg}
              className="text-sm font-medium text-primary !bg-transparent"
            >View visual aid</Button>
            <Button
              block
              type="text"
              onClick={onViewRand}
              loading={postMemorizeLoad}
              className="text-sm font-medium text-[#C1C1C0] !bg-transparent"
            >{isView ? "Close" : "Tap to view the meaning"}</Button>
          </div>
        </div>

        <div className="w-full">
          <div className="w-fit mx-auto px-4">
            <Button
              onClick={() => setCanPractice(true)}
              className="border-primary text-primary px-[50px] md:px-[134px] w-fit mx-auto rounded-full"
            >
              Match Challenge
            </Button>
          </div>
          <Divider />
          <div className="flex gap-10 justify-center items-center">
            <Button
              className=" rounded-xl"
              onClick={() => {
                setActiveQuest(activeQuest - 1);
                setIsView(false);
              }}
              disabled={!activeQuest}
              size="large"
            >
              Prev
            </Button>
            <Button
              className="border-primary text-primary rounded-xl"
              disabled={isEqual(
                activeQuest + 1,
                getFlashcardData?.data?.flashCards?.length
              )}
              onClick={() => {
                setActiveQuest(activeQuest + 1);
                setIsView(false);
              }}
              size="large"
            >
              Next
            </Button>
            <Button
              hidden={
                activeQuest + 1 !== getFlashcardData?.data?.flashCards?.length
              }
              className="border-primary text-primary rounded-xl"
              onClick={() => {
                setCanAdd(true);
              }}
              size="large"
            >
              Add more
            </Button>
          </div>
        </div>
        <aside
          ref={pdfRef}
          className="w-full max-w-[596px] absolute -top-[200vh] -left-full"
        >
          <div className="h-9 bg-[#4970fc]" />
          <div className="">
            <div className="flex gap-3 items-center pt-4 px-4">
              <img
                src="/icon.png"
                alt=""
                className="w-[60px] h-[60px] object-contain"
              />
              <p className="font-bold text-2xl">
                {getFlashcardData?.data?.name}
              </p>
            </div>
            <Divider />
          </div>
          <aside className="pb-4 px-4 space-y-[38px]">
            {getFlashcardData?.data?.flashCards?.map(
              (item: any, index: number) => (
                <article className="">
                  <h1 className="font-bold text-xl">{item.word}</h1>
                  <p className="font-normal text-xl">{item.meaning}</p>
                </article>
              )
            )}
          </aside>
        </aside>
        {showCustomize && (
          <CustomizeModal
            open={showCustomize}
            setShowCustomize={setShowCustomize}
            word={CurrentFlashcard?.word}
            meaning={CurrentFlashcard?.meaning}
            setCustomized={setCustomized}
          />
        )}
        {canPractice && (
          <MatchingFlashcards
            canPractice={canPractice}
            setCanPractice={setCanPractice}
            flashcard={getFlashcardData?.data?.flashCards}
            is_matched={getFlashcardData?.data?.is_matched}
          />
        )}
        <AddFlashcardModal
          open={canAdd}
          setShowCustomize={setCanAdd}
          setCustomized={setCustomized}
        />

        <FeedbackModal
          showModal={showFeedBack}
          setShowModal={setShowFeedBack}
          featureId={id}
          feature="flash_cards"
        />

        <RateLecturerModal
          showModal={showRateLecturer}
          setShowModal={setShowRateLecturer}
          featureId={id}
          feature="flash_cards"
        />
        <ShareToUsersModal
          open={openShare}
          setOpenShare={setOpenShare}
          type="flashcards"
        />

        {/* visual aid modal >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */}
        <Modal
          styles={{
            content: {
              background: "none",
              padding: 0,
            },
          }}
          className='!w-[70vw]'
          onCancel={onClose}
          closeIcon={false}
          footer={false}
          open={isOpen}
        >
          <div className="w-full h-full">
            <div className="w-full bg-[#DBE2FE] p-5 md:p-10 flex justify-between items-end relative rounded-t-3xl">
              <p className='text-3xl font-semibold text-[#1B1B1B]'>Visual aid</p>
              <img className='w-[100px] md:w-[120px] !absolute !bottom-0 !right-0' alt='cite' src={searchCiteIcon}/>
            </div>
            <Spin spinning={postFlashImgLoad}>
              <div className='w-full h-[80vh] overflow-y-auto bg-white p-5 md:px-10 space-y-5'>
                <Image
                  src={`data:image/jpeg;base64, ${postFlashImgData?.data?.contents?.data}`}
                  alt={postFlashImgData?.data?.contents?.query}
                  className="!w-full"
                />
                <p className="text-base font-medium text-[#676767]">Visual Aid For</p>
                <p className="w-full border border-[#E0E0E0] rounded-md p-2 text-sm font-normal text-[#414141]">*{postFlashImgData?.data?.contents?.query}*</p>
              </div>
            </Spin>
          </div>
        </Modal>
      </div>
    </Spin>
  );
};

export default FlashcardSection;
