import { lazy } from "react";
import Conversation from "../pages/main/conversation";
import AuthenticateConversations from "../pages/main/conversations-authentication";

// auth pages >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
const Authentication = lazy(() => import("../pages/auth"));
const Login = lazy(() => import("../pages/auth/login"));
const Verify = lazy(() => import("../pages/auth/verify"));
const AuthType = lazy(() => import("../pages/auth/type"));
const Password = lazy(() => import("../pages/auth/password"));
const Forgot = lazy(() => import("../pages/auth/forgot"));
const Reset = lazy(() => import("../pages/auth/reset"));
const Logout = lazy(() => import("../pages/auth/logout"));

// teacher pages >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
const TeacherAuth = lazy(() => import("../pages/teacher/auth"));

// public pages >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
const PublicDemo = lazy(() => import("../pages/public/demo"));
const NuroExtRecaps = lazy(() => import("../pages/public/recaps"));
const PublicQuiz = lazy(() => import("../pages/public/quiz"));
const PublicRecap = lazy(() => import("../pages/public/recap"));
const PublicFlashcards = lazy(() => import("../pages/public/flashcard"));
const PublicTermsAndConditions = lazy(() => import("../pages/public/termsAndConditions"));

// main pages >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
const Conversations = lazy(() => import("../pages/main/conversations"));
const Home = lazy(() => import("../pages/main/home"));
const Course = lazy(() => import("../pages/main/course"));
const StudyGuide = lazy(() => import("../pages/main/studyguide"));
const StudySession = lazy(() => import("../pages/main/studysession"));
const LeaderBoard = lazy(() => import("../pages/main/leaderboard"));
const Flashcard = lazy(() => import("../pages/main/flashcard"));
const Settings = lazy(() => import("../pages/main/settings"));
const Discuss = lazy(() => import("../pages/main/discuss"));
const Recap = lazy(() => import("../pages/main/recap"));
const Quiz = lazy(() => import("../pages/main/quiz"));
const Search = lazy(() => import("../pages/main/search"));
const Ranking = lazy(() => import("../pages/main/ranking"));
const Citation = lazy(() => import("../pages/main/citation"));
const Translate = lazy(() => import("../pages/main/translate"));
const CodingTutor = lazy(() => import("../pages/main/codingtutor"));
const AdaptiveLearning = lazy<any>(() => import("../pages/main/adaptive-learning"));


export const routes = [
  // main pages >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
  {
    path: "/",
    label: "Lectures",
    component: <Home />,
  },
  {
    path: "/course",
    label: "Courses",
    component: <Course />,
  },
  {
    path: "/flashcard",
    label: "Flashcards",
    component: <Flashcard />,
  },
  {
    path: "/quiz",
    label: "Quiz",
    component: <Quiz />,
  },
  {
    path: "/recap",
    label: "Recaps",
    component: <Recap />,
  },
  {
    path: "/discuss",
    label: "Discuss",
    component: <Discuss />,
  },
  {
    path: "/study-guide",
    label: "Study Guide",
    component: <StudyGuide />,
  },
  {
    path: "/study-session",
    label: "Study Session",
    component: <StudySession />,
  },
  {
    path: "/leader-board",
    label: "Leader Board",
    component: <LeaderBoard />,
  },
  {
    path: "/conversations",
    label: "Conversations",
    component: <Conversations />,
  },
  {
    path: "/conversations/authenticate",
    label: "Authenticate Conversation",
    component: <AuthenticateConversations />,
  },
  {
    path: "/conversations/:id",
    label: "ConversationsId",
    component: <Conversation />,
  },
  {
    path: "/settings",
    label: "Account Settings",
    component: <Settings />,
  },
  {
    path: "/search",
    label: "Search",
    component: <Search />,
  },
  {
    path: "/ranking",
    label: "User Ranking",
    component: <Ranking />,
  },
  {
    path: "/translate",
    label: "Translate",
    component: <Translate />,
  },
  {
    path: "/citation",
    label: "Citation",
    component: <Citation />,
  },
  {
    path: "/coding-tutor",
    label: "Coding Tutor",
    component: <CodingTutor />,
  },
  {
    path: "/als",
    label: "Adaptive Learning",
    component: <AdaptiveLearning />,
  },

  // teacher pages >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
  {
    path: "/teacher",
    label: "Teacher",
    component: <TeacherAuth />,
  },
  
  // public pages >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
  {
    path: "/page/demo",
    label: "Public Demo",
    component: <PublicDemo />,
  },
  {
    path: "/page/recaps",
    label: "Nuro Ext Recap",
    component: <NuroExtRecaps />,
  },
  {
    path: "/page/quiz",
    label: "Public Quiz",
    component: <PublicQuiz />,
  },
  {
    path: "/page/recap",
    label: "Public Recap",
    component: <PublicRecap />,
  },
  {
    path: "/page/flashcard",
    label: "Public Flashcards",
    component: <PublicFlashcards />,
  },
  {
    path: "/page/terms",
    label: "Terms and Conditions",
    component: <PublicTermsAndConditions />,
  },

  // auth pages >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
  {
    path: "/auth",
    label: "Authentication",
    component: <Authentication />,
  },
  {
    path: "/auth/login",
    label: "Login",
    component: <Login />,
  },
  {
    path: "/auth/verify/:id",
    label: "Verify",
    component: <Verify />,
  },
  {
    path: "/auth/type",
    label: "Auth Type",
    component: <AuthType />,
  },
  {
    path: "/auth/password",
    label: "Set Password",
    component: <Password />,
  },
  {
    path: "/auth/forgot",
    label: "Forgot Password",
    component: <Forgot />,
  },
  {
    path: "/auth/reset",
    label: "Reset Password",
    component: <Reset />,
  },
  {
    path: "/auth/logout",
    label: "Logout",
    component: <Logout />,
  },
];
